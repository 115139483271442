<template>
  <div class="content row">
    <div class="col-sm-12 col-md-12 col-xl-12">
      <!--      <img src="https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=600&h=200&fit=clamp&h=160&h&duotone=black,00bbff&duotone-alpha=10" width="100%">-->
      <h1 class="tulum-party-title">Los Cabos - Stories to Tell</h1>
      <div id="articles" class="row">
        <div class="lds-dual-ring" v-if="this.$store.state.status.loading">
          <h4>Spinning the Hamster.</h4>
        </div>
        <div v-for="(article, idx) in articles" class="col-md-6" :key="idx">
          <HorizontalCard
            :link="`/article/${article.slug}`"
            width="200"
            height="200"
            :image="article.metadata.media.imgix_url"
            :title="article.title"
            :sub-title="article.metadata.caption"
          ></HorizontalCard>
        </div>
      </div>

      <hr />
      <p>
        Showing {{ val > 1 ? pageSize + 1 : 1 }} -
        {{ articles ? articles.length + (val > 1 ? pageSize : 0) : 0 }} of
        {{ total }} ARTICLE |
        <a
          style="display: inline-block"
          class="btn btn-link"
          @click="showAll"
          v-if="!showingAll"
          >Show all ({{ total }})</a
        >
      </p>

      <BasePagination
        type="primary"
        :per-page="pageSize"
        :total="total"
        :value="val"
        v-if="!showingAll"
        :nextPage="nextPage"
        :prevPage="prevPage"
        :changePage="changePage"
      ></BasePagination>
    </div>
  </div>
</template>

<script>
import BasePagination from '../../components/BasePagination.vue';
import HorizontalCard from '@/components/Cards/HorizontalCard';
export default {
  name: 'Blog',
  metaInfo() {
    return {
      title: `CABO.PARTY BLOG`,
      meta: [
        {
          name: 'description',
          content: `Cabo Articles`,
        },
        {
          name: 'keywords',
          content: `Cabo Party Blog, Articles, Nightlife, Cabo Club Scene, Travel Recommendations`,
        },
      ],
    };
  },
  props: ['slug'],
  data: () => {
    return {
      table: [],
      showingAll: false,
      showPalms: false,
      pageSize: 20,
      val: undefined,
      page: 0,
      showModal: false,
    };
  },
  components: {
    HorizontalCard,
    BasePagination,
  },
  created: function () {
    // this.$store.dispatch('getArtists');
    this.$store.dispatch('getArticles', { start: 0, limit: this.pageSize });
  },
  methods: {
    changePage: function (page) {
      this.page = page;
      this.val = this.page;
      this.$store.dispatch('getArticles', {
        start: this.page * this.pageSize,
        limit: this.pageSize,
      });
    },
    nextPage: function () {
      this.page++;
      this.val = this.page;
      this.$store.dispatch('getArticles', {
        start: this.page * this.pageSize,
        limit: this.pageSize,
      });
    },
    showAll: function () {
      this.$store.dispatch('getArticles', { start: 0, limit: this.total });
      this.showingAll = true;
    },
    prevPage: function () {
      this.page--;
      this.val = this.page;

      this.$store.dispatch('getArticles', {
        start: this.page * 10,
        limit: this.pageSize,
      });
    },
  },
  computed: {
    total: {
      get() {
        return this.$store.state.total;
      },
    },
    articles: {
      get() {
        return this.$store.state.articles;
      },
    },
  },
};
</script>
